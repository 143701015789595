// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import "./controllers"


// MAKE BOOTSTRAP GLOBAL
window.bootstrap = bootstrap


document.addEventListener("turbo:load", function() {
  var frame = document.getElementById('proposals');

  if (frame) {
    frame.addEventListener("click", function(e) {
      frame.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }
});


window.addEventListener('popstate', function(event) {
  window.location.replace(window.location.href);
});