import { Controller } from "@hotwired/stimulus"
import { TempusDominus } from '@eonasdan/tempus-dominus';

import { post } from "@rails/request.js"

// Connects to data-controller="tempus-dominus"
export default class extends Controller {
  static targets = [ "input" ]
  static values = {
    url: String,
    param: String,
    weekdays: Array,
    view: String
  }

  connect() {
    const myDatepicker = document.getElementById('datepicker')

    new TempusDominus(myDatepicker, {
      display: {
        icons: {
          type: 'icons',
          previous: 'custom-icon-prev',
          next: 'custom-icon-next'
        },
        theme: 'light',
        viewMode: 'calendar',
        components: {
          decades: false,
          year: true,
          month: true,
          date: true,
          hours: false,
          minutes: false,
          seconds: false
        }
      },
      localization: {
        locale: 'de',
        startOfTheWeek: 1,
        format: 'dd.MM.YYYY'
      },
      restrictions: {
        minDate: this.viewValue,
        daysOfWeekDisabled: this.weekdaysValue
      },
      useCurrent: false,
      viewDate: this.viewValue
    });


    myDatepicker.addEventListener('change.td', (e) => {
      let params = new URLSearchParams()
      params.append(this.paramValue, this.inputTarget.value)

      post(`${this.urlValue}?${params}`, {
        responseKind: "turbo-stream"
      })
    })
  }

  disconnect() {
    super.disconnect();
  }

}
