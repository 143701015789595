import { Controller } from "@hotwired/stimulus"
import {get, post} from "@rails/request.js"

// Connects to data-controller="checkout"
export default class extends Controller {
  static targets = [ "input" ]
  static values = {
    url: String,
    param: String
  }

  voucher(){
    let params = new URLSearchParams()
    params.append("voucher_code", this.inputTarget.value)

    post(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  change(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)

    post(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

}
