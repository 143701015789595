import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="eyecatcher"
export default class extends Controller {
  static modalDisplayed = false

  connect() {
    if (!this.constructor.modalDisplayed && document.cookie.indexOf("IntroSelect=true") < 0 && !document.documentElement.hasAttribute("data-turbo-preview")) {
      setTimeout(() => {
        const modal = new window.bootstrap.Modal(this.element);
        modal.show();
      }, 30000); // 30 seconds in milliseconds

      this.constructor.modalDisplayed = true
    }

    // this.element.querySelector(".btn-close").addEventListener("click", () => {
    //   this.setCookie();
    // });

    const linksAndButtons = this.element.querySelectorAll("a, button");
    linksAndButtons.forEach(element => {
      element.addEventListener("click", this.handleClick.bind(this));
    });
  }


  handleClick(event) {
    console.log('test')
    this.setCookie();
  }


  setCookie() {
    const now = new Date();
    now.setTime(now.getTime() + 12 * 3600 * 1000);
    document.cookie = "IntroSelect=true; expires=" + now.toUTCString() + "; path=/";
  }

}
