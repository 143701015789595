import { Controller } from "@hotwired/stimulus"
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

// Connects to data-controller="masonry"
export default class extends Controller {
  connect() {
    this.masonry = new Masonry(this.element, {
      itemSelector: '.item',
      percentPosition: true
    })
    imagesLoaded(this.element, () => {
      this.masonry.layout()
    })
  }

  disconnect() {
    this.masonry.destroy()
    this.masonry = null
  }
}
