import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  connect() {
    const collapseElementList = document.querySelectorAll("form .collapse")
    collapseElementList.forEach(element => {
      if (element.querySelector('input:checked') !== null) {
        const collapse = new window.bootstrap.Collapse(element)
        if (collapse !== null) {
          collapse.show();
        }
      }
    });
  }

  submit() {
    this.element.requestSubmit();
    const bsOffcanvas = window.bootstrap.Offcanvas.getInstance('#search_fields')
    if (bsOffcanvas !== null) {
      bsOffcanvas.hide();
    }
  }
}
