import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'


// Connects to data-controller="swiper"
export default class extends Controller {

  connect() {
    this.swiper = new Swiper(this.element,  {
      slidesPerView: 1.5,
      spaceBetween: 24,
      loop: true,
      loopAddBlankSlides: true,
      creativeEffect: {
        limitProgress: 5,
        prev: { shadow: true },
        next: { shadow: true },
      },
      navigation: {
        enabled: false,
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
      lazy: { enabled: true },
      watchSlidesProgress: true,
      breakpoints: {
        576: { slidesPerView: 2, navigation: { enabled: true } },
        768: { slidesPerView: 3, navigation: { enabled: true } },
        1200: { slidesPerView: 4, navigation: { enabled: true } }
      },
      breakpointsBase: 'window',
      slidesPerGroupAuto: false,
    });
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }
}
