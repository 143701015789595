import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["content", "link", "backLink"]

  connect() {
    this.navExpand = this.element.querySelectorAll('.submenu');
    this.backLinkHtml = "<li class=\"back-link\"><a href=\"javascript:;\">zurück zur Übersicht</a></li>"

    this.navExpand.forEach(item => {
      item.querySelector('.submenu-content').insertAdjacentHTML('afterbegin', this.backLinkHtml)

      item.querySelector('a').addEventListener('click', (event) => {
        event.preventDefault();
        item.classList.add('active');
      });

      item.querySelector('.back-link').addEventListener('click', (event) => {
        event.preventDefault();
        item.classList.remove('active');
      });
    });
  }


  disconnect() {
    this.navExpand = this.element.querySelectorAll('.submenu');

    this.navExpand.forEach(item => {
      const backLink = item.querySelector('.back-link');
      if (backLink) {
        backLink.remove();
      }
    });
  }
}